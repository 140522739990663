import React, { useEffect, useState } from 'react';
import '../components/Photos.css'; // Ensure your CSS is imported
import panelGif from '../imgs/Panel1.png'; // Adjust the path based on your folder structure
import panelHead from '../imgs/angel4.gif';

const Photos = () => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Trigger animation on component mount
    setAnimate(true);
  }, []);

  const splitText = (text) => {
    return text.split('').map((char, index) => (
      <span
        key={index}
        style={{ animationDelay: `${index * 0.1}s` }}
        className="letter"
      >
        {char}
      </span>
    ));
  };

  // Image sources with paths and descriptions
  const imageSources = [
    { src: '/imgs/image1.jpg', alt: 'Image 1' },
    { src: '/imgs/image2.jpg', alt: 'Image 2' },
    { src: '/imgs/image3.jpg', alt: 'Image 3' },
    { src: '/imgs/image4.jpg', alt: 'Image 4' },
    { src: '/imgs/image5.jpg', alt: 'Image 5' },
    { src: '/imgs/image6.jpg', alt: 'Image 6' },
    { src: '/imgs/image7.jpg', alt: 'Image 7' },
    { src: '/imgs/image8.jpg', alt: 'Image 8' },
    { src: '/imgs/image9.jpg', alt: 'Image 9' },
    { src: '/imgs/image10.jpg', alt: 'Image 10' },
    { src: '/imgs/image11.jpg', alt: 'Image 11' },
    { src: '/imgs/image12.jpg', alt: 'Image 12' },
    { src: '/imgs/image13.jpg', alt: 'Image 13' },
    { src: '/imgs/image14.jpg', alt: 'Image 14' },
    { src: '/imgs/image15.jpg', alt: 'Image 15' },
    { src: '/imgs/image16.jpg', alt: 'Image 16' },
    { src: '/imgs/image17.jpg', alt: 'Image 17' },
    { src: '/imgs/image18.jpg', alt: 'Image 18' },
    { src: '/imgs/image19.jpg', alt: 'Image 19' },
    { src: '/imgs/image20.jpg', alt: 'Image 20' },
    { src: '/imgs/image21.jpg', alt: 'Image 21' },
    { src: '/imgs/image22.jpg', alt: 'Image 22' },
    { src: '/imgs/image23.jpg', alt: 'Image 23' },
    { src: '/imgs/image24.jpg', alt: 'Image 24' },
    { src: '/imgs/image25.jpg', alt: 'Image 25' },
    { src: '/imgs/image26.jpg', alt: 'Image 26' },
    { src: '/imgs/image27.jpg', alt: 'Image 27' },
    { src: '/imgs/image28.jpg', alt: 'Image 28' },
    { src: '/imgs/image29.jpg', alt: 'Image 29' },
    { src: '/imgs/image30.jpg', alt: 'Image 30' },
  ];

  // Image positions in the grid (example: placing images in different grid cells)
  const imageLayout = [
    [0, 1, 2],  // Row 1: Image 1, Image 2, Image 3
    [3, 4, 5],  // Row 2: Image 4, Image 5, Image 6
    [6, 7, 8],  // Row 3: Image 7, Image 8, Image 9
    [9, 10, 11], // Row 4: Image 10, Image 11, Image 12
    [12, 13, 14], // Row 5: Image 13, Image 14, Image 15
    [15, 16, 17], // Row 6: Image 16, Image 17, Image 18
    [18, 19, 20], // Row 7: Image 19, Image 20, Image 21
    [21, 22, 23], // Row 8: Image 22, Image 23, Image 24
    [24, 25, 26], // Row 9: Image 25, Image 26, Image 27
    [27, 28, 29], // Row 10: Image 28, Image 29, Image 30
  ];

  return (
    <div id="photos" style={styles.container}>
      {/* Image Element */}
      {/* <img src={panelHead} alt="Panel Gif" className="image-head" /> */}

      <h1 className={animate ? 'slide-in' : ''}>
        <div className="h1-wrapper">
          {splitText('Photos')}
        </div>
      </h1>
      <p>Here you'll find a collection of our work in photography and videography.</p>
      <div style={styles.grid}>
        {imageLayout.map((row, rowIndex) => (
          <div key={rowIndex} style={styles.row}>
            {row.map((imageIndex, cellIndex) => {
              const image = imageSources[imageIndex];
              return image ? (
                <div key={cellIndex} style={styles.imageContainer}>
                  <img src={image.src} alt={image.alt} className="image-content" />
                </div>
              ) : (
                <div key={cellIndex} style={styles.emptyCell}></div>
              );
            })}
          </div>
        ))}
      </div>
      <img src={panelGif} alt="Panel Gif" className="image-panel" />
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    textAlign: 'center',
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    width: '100%',
  },
  imageContainer: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5), inset 0 0 30px rgba(0, 0, 0, 0.7)',
    margin: '0 auto',
    width: '100%',
    maxWidth: '300px',
    height: 'auto', // Allow height to adjust based on image
  },
  emptyCell: {
    width: '100%',
    maxWidth: '300px',
    height: 'auto', // This will create a gap for cells without images
  },
};

export default Photos;
