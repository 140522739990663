import React, { useEffect, useState } from 'react';
import './Projects.css'; // Ensure your CSS is imported here

const Projects = () => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Trigger animation on component mount
    setAnimate(true);
  }, []);

  const splitText = (text) => {
    return text.split('').map((char, index) => (
      <span
        key={index}
        style={{ animationDelay: `${index * 0.1}s` }}
        className="letter vignette-text"
      >
        {char}
      </span>
    ));
  };

  return (
    <div id="projects" style={styles.container}>
      <h1 className={animate ? 'slide-in vignette-text' : 'vignette-text'}>
        <div className="h1-wrapper">
          {splitText('Projects')}
        </div>
      </h1>
      <div style={styles.intro}>
        <p className="vignette-text">
          Here, we showcase the creative explorations in alternative fashion and photography that define our aesthetic. We are passionate about pushing the boundaries of traditional fashion norms and capturing visual narratives through our lens. Check out some of our features in
          <a href="https://www.magcloud.com/browse/issue/1973856" target="_blank" rel="noopener noreferrer"> Moevir Magazine May Issue 2021</a>,
          <a href="https://www.thisismob.shop/products/mob-journal-volume-fifteen-issue-26" target="_blank" rel="noopener noreferrer"> MOB JOURNAL | VOLUME FIFTEEN | ISSUE #26</a>, and
          <a href="https://www.magcloud.com/browse/issue/2166525?__r=4479372" target="_blank" rel="noopener noreferrer"> ISSUE 1 | VOL. 1</a>.
        </p>
        <p className="vignette-text">
          Our work spans a range of projects, from avant-garde fashion shoots to conceptual photography series. We aim to blend style, emotion, and art in each project, making each piece a unique visual story. Explore more of our features in
          <a href="https://www.thisismob.shop/products/mob-journal-volume-sixteen-issue-36" target="_blank" rel="noopener noreferrer"> MOB JOURNAL | VOLUME SIXTEEN | ISSUE #36</a>,
          <a href="https://www.thisismob.shop/collections/mob-journal-volume-eleven/products/boys-boys-boys-boys-volume-four-issue-16" target="_blank" rel="noopener noreferrer"> BOYS BOYS BOYS BOYS | VOLUME FOUR | ISSUE #16</a>, and
          <a href="https://www.magcloud.com/browse/issue/2634662" target="_blank" rel="noopener noreferrer"> SAINTEIGHT NO.144 OCTOBER 2023</a>.
        </p>
      </div>
      <div style={styles.gallery}>
        <div style={styles.imageContainer}>
          <img src="/imgs/image1.jpg" alt="Alternative Fashion 1" style={styles.image} />
          <p className="vignette-text" style={styles.caption}>Avant-Garde Fashion</p>
        </div>
        <div style={styles.imageContainer}>
          <img src="/imgs/image2.jpg" alt="Alternative Fashion 2" style={styles.image} />
          <p className="vignette-text" style={styles.caption}>Conceptual Photography</p>
        </div>
        {/* Add more image containers as needed */}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    textAlign: 'center',
  },
  intro: {
    margin: '20px auto',
    maxWidth: '800px',
    textAlign: 'left',
  },
  gallery: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '20px',
    justifyContent: 'center',
    marginTop: '20px',
  },
  imageContainer: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '10px',
  },
  image: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    transition: 'transform 0.3s, box-shadow 0.3s',
  },
  caption: {
    marginTop: '10px',
    fontStyle: 'italic',
  },
};

export default Projects;
