import React, { useEffect, useState } from 'react';
import './Services.css';

const Services = () => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const splitText = (text) => {
    return text.split('').map((char, index) => (
      <span
        key={index}
        style={{ animationDelay: `${index * 0.1}s` }}
        className="letter"
      >
        {char === ' ' ? '\u00A0' : char}
      </span>
    ));
  };

  return (
    <div id="services" style={styles.container}>
      <h1 className={animate ? 'slide-in' : ''}>
        <div className="h1-wrapper">
          {splitText('Our Services')}
        </div>
      </h1>
      <div style={styles.content}>
        <div className="service-item vignette-text">
          <h2>{splitText('Fashion Design and Production')}</h2>
          <p>
            Specializing in the creation of one-of-a-kind clothing for men and women, using thrifted and discarded materials to craft high fashion and avant-garde designs.
          </p>
        </div>
        <div className="service-item vignette-text">
          <h2>{splitText('Photography and Videography')}</h2>
          <p>
            Offering installation and editorial photography, as well as videography services utilizing a collection of over 100 vintage film and VHS cameras to create unique, nostalgic visuals.
          </p>
        </div>
        <div className="service-item vignette-text">
          <h2>{splitText('Media Production')}</h2>
          <p>
            Providing artist radio placement, podcast hosting and production, and independent YouTube channel management to help creators develop engaging content and grow their audiences.
          </p>
        </div>
        <div className="service-item vignette-text">
          <h2>{splitText('Model Services')}</h2>
          <p>
            Facilitating model magazine features, fashion show placements, and offering pose and runway coaching to help models refine their craft and gain exposure in the fashion industry.
          </p>
        </div>
        <div className="service-item vignette-text">
          <h2>{splitText('Streamer and YouTube Creator Development')}</h2>
          <p>
            Supporting streamers and YouTube creators with content creation, channel management, and audience engagement strategies to build and sustain a loyal fanbase.
          </p>
        </div>
        <div className="service-item vignette-text">
          <h2>{splitText('Artist Development and Representation')}</h2>
          <p>
            Offering talent development, coaching, representation, and booking services for artists and models to enhance their careers and secure opportunities for events, shows, and media appearances.
          </p>
        </div>
        <div className="service-item vignette-text">
          <h2>{splitText('Adult Content Creation')}</h2>
          <p>
            Specializing in OnlyFans content creation and management, adult content filming and production, and providing booking and development services for adult content creators.
          </p>
        </div>
        <div className="service-item vignette-text">
          <h2>{splitText('Fashion and Media Consulting')}</h2>
          <p>
            Providing creative direction and styling, sustainable fashion consulting, and media strategy and brand development to help clients achieve their vision and stand out in their respective industries.
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    textAlign: 'center',
    maxWidth: '900px',
    margin: '0 auto',
  },
  content: {
    marginTop: '20px',
    textAlign: 'left',
  },
  serviceItem: {
    marginBottom: '40px',
  },
};

export default Services;
