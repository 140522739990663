import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav style={styles.navbar}>
      <ul style={styles.navList}>
        <li style={styles.navItem}><Link to="/">Home</Link></li>
        <li style={styles.navItem}><Link to="/photos">Photos</Link></li>
        <li style={styles.navItem}><Link to="/projects">Projects</Link></li>
        <li style={styles.navItem}><Link to="/services">Services</Link></li>
        <li style={styles.navItem}><Link to="/fashion">Fashion</Link></li>
        <li style={styles.navItem}><Link to="/contact">Contact</Link></li>
      </ul>
    </nav>
  );
};

const styles = {
  navbar: {
    backgroundColor: '#000',
    padding: '10px',
  },
  navList: {
    listStyleType: 'none',
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
  },
  navItem: {
    margin: '0 15px',
  },
};

export default Navbar;
