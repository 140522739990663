import React from 'react';
import './Fashion.css';

const clothingItems = [
  { src: '/imgs/image01.jpeg', alt: 'Fashion Item 1' },
  { src: '/imgs/image02.jpeg', alt: 'Fashion Item 2' },
  { src: '/imgs/image03.jpeg', alt: 'Fashion Item 3' },
  { src: '/imgs/image04.jpeg', alt: 'Fashion Item 4' },
  { src: '/imgs/image05.jpeg', alt: 'Fashion Item 5' },
  { src: '/imgs/image06.jpeg', alt: 'Fashion Item 6' },
  { src: '/imgs/image07.jpeg', alt: 'Fashion Item 7' },
];

const ClothingItems = () => {
  return (
    <div className="clothing-container">
      <h1 className="vintage-header">Fashion Clothing Items</h1>
      <div className="clothing-grid">
        {clothingItems.map((item, index) => (
          <div key={index} className="clothing-item">
            <img src={item.src} alt={item.alt} className="clothing-image" />
          </div>
        ))}
      </div>
      <div className="contact-section">
        <h2>Interested in an Item?</h2>
        <p>Contact me for more information or to make a purchase.</p>
        <a href="mailto:admin@lnfmediagroup.com" className="contact-button">Contact Me</a>
      </div>
    </div>
  );
};

export default ClothingItems;
