import React, { useEffect, useState } from 'react';
import './Home.css';
import angelGIF from '../imgs/angel3.gif'; // Adjust the path based on your folder structure

const Home = () => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Trigger animation on component mount
    setAnimate(true);
  }, []);

  const splitText = (text) => {
    return text.split('').map((char, index) => (
      <span
        key={index}
        style={{ animationDelay: `${index * 0.1}s` }}
        className="letter vignette-text"
      >
        {char === ' ' ? '\u00A0' : char}
      </span>
    ));
  };

  return (
    <div id="bio" style={styles.container}>
      {/* Image Element */}
      {/* <img src={angelGIF} alt="Ange Gif" style={styles.image} /> */}
      
      <h1 className={animate ? 'slide-in' : ''}>
        <div className="h1-wrapper vignette-text">
          {splitText('Welcome to LnF Media Group')}
        </div>
      </h1>
      <p className={animate ? 'slide-in vignette-text paragraph' : 'vignette-text paragraph'}>
        LnF Media Group specializes in fashion design, photography, media production, and artist development. We create unique, high-fashion clothing using sustainable materials, capture compelling visuals through vintage film and VHS, and help artists and creators build their brand across various platforms. Our services extend to model representation, YouTube creator development, and adult content creation, ensuring a professional and captivating online presence. Explore our projects and contact us to collaborate on your next creative endeavor.
      </p>
    </div>
  );
};

const styles = {
  container: {
    padding: '10px',
    textAlign: 'center',
  },
  image: {
    width: '30%',
    // marginTop: '10px',
  },
};

export default Home;
