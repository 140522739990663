import React from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Photos from "./components/Photos";
import Contact from "./components/Contact";
import Projects from "./components/Projects";
import Footer from "./components/Footer";
import AudioPlayer from "./components/AudioPlayer";
import Services from "./components/Services";
import Fashion from "./components/Fashion";

const pagePaths = ["/", "/photos", "/contact", "/projects"];

function App() {
  const navigate = useNavigate();

  const handleSwipe = (direction) => {
    const currentPath = window.location.pathname;
    const currentIndex = pagePaths.indexOf(currentPath);

    if (direction === "LEFT" && currentIndex < pagePaths.length - 1) {
      navigate(pagePaths[currentIndex + 1]);
    } else if (direction === "RIGHT" && currentIndex > 0) {
      navigate(pagePaths[currentIndex - 1]);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe("LEFT"),
    onSwipedRight: () => handleSwipe("RIGHT"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div {...handlers} className="App">
      {/* Video Background */}
      <video autoPlay loop muted id="background-video">
        <source src={require("./assets/background.mp4")} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Navbar />
      <AudioPlayer />
      {/* Header with Logo */}
      <header style={styles.header}>
        {/* <img src={require("./imgs/logo1.gif")} alt="Logo" /> */}
      </header>
      {/* Navbar */}
      {/* Routes */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/photos" element={<Photos />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/services" element={<Services />} />
        <Route path="/fashion" element={<Fashion />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      {/* Footer */}
      <Footer />
    </div>
  );
}

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

const styles = {
  header: {
    backgroundColor: "transparent", // Transparent to show the video background
    padding: "20px",
    color: "white",
    textAlign: "center",
    position: "relative",
    zIndex: 1, // Ensures the header appears above the video
  },
};

export default AppWithRouter;
