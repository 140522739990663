import React, { useRef, useState, useEffect } from 'react';
import './AudioPlayer.css';

const AudioPlayer = () => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [volume, setVolume] = useState(1);

  useEffect(() => {
    // Automatically play the audio when the component mounts
    if (audioRef.current) {
      audioRef.current.play().catch(error => {
        // Handle any errors (e.g., if autoplay is blocked by the browser)
        console.error("Autoplay error:", error);
      });
    }
  }, []);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const stopAudio = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setIsPlaying(false);
  };

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value;
    audioRef.current.volume = newVolume;
    setVolume(newVolume);
  };

  return (
    <div className="audio-player">
      <button onClick={togglePlayPause} className="play-pause-btn">
        {isPlaying ? '⏸️' : '▶️'}
      </button>
      <button onClick={stopAudio} className="stop-btn">⏹️</button>
      <div className="track-info">
        <marquee behavior="scroll" direction="left">Baby I Been Wasting Time.mp3</marquee>
      </div>
      <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={volume}
        onChange={handleVolumeChange}
        className="volume-control"
      />
      <audio ref={audioRef} autoPlay>
        <source src={require('../assets/baby i been wasting time,.mp3')} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AudioPlayer;
