import React from 'react';

const Contact = () => {
  return (
    <div id="contact" style={styles.container}>
      <img src="/imgs/angel1.gif" alt="Sample Photo" style={styles.image} />

      <h1>Contact</h1>
      <p>Contact: admin@lnfmediagroup.com</p>
      {/* Add more images and content as needed */}
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    textAlign: 'center',
  },
  image: {
    width: '-10%',
    // marginTop: '20px',
  },
};

export default Contact;
